import React from 'react';
import {Image,Row,Col,Button} from "react-bootstrap";
import './platzlalm.css';
import platzlalm1 from "../../assets/images/platzlalm/platzlalm5.jpg";
import platzlalm2 from "../../assets/images/platzlalm/platzlalm2.jpg";
import platzlalm3 from "../../assets/images/platzlalm/platzlalm3.jpg";
import platzlalm4 from "../../assets/images/platzlalm/platzlalm4.jpg";

const PlatzlalmComponent = (props) => {
    return (
        <Col lg={{span:8, offset:2}}>
            <Row>
                <Col md="6" className="mb-2">
                    <Image srcSet={platzlalm1} fluid={true}></Image>
                </Col>
                <Col md="6" className="mb-2">
                    <Image srcSet={platzlalm4} fluid={true}></Image>
                </Col>
            </Row>
            <Row>
                <Col md="6" className="mb-2">
                    <Image srcSet={platzlalm2} fluid={true}></Image>
                </Col>
                <Col md="6" className="mb-2">
                    <Image srcSet={platzlalm3} fluid={true}></Image>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p className="text-center pt-4">
                        <Button href="https://www.platzlalm.at" variant="light" target="_blank" className="m-2">Webseite der Platzlalm</Button>
                        <Button href="https://my.matterport.com/show/?m=ihe6ifNvEZM" variant="light" target="_blank" className="m-2">Die PlatzlAlm in 360°</Button>
                        <Button href="https://www.youtube.com/watch?v=vTfUi-CbCJ0" variant="light" target="_blank" className="m-2">Die PlatzlAlm live</Button>

                    </p>
                </Col>
            </Row>
        </Col>

    )
}

export default PlatzlalmComponent;