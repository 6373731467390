import React from 'react';
import './timeplan.css';

const TimePlanComponent = (props) => {
    return (
        <div id="timeplan">
            <h1 className="text-center">Ablaufplan</h1>
            <p className="text-center">
                Folgend findet ihr den vorläufigen Ablaufplan.
                <br/>
                Update vom 19.06.2023
            </p>
            <table>
                <tr>
                    <td>Mi<span className="xs-hide">ttwoch</span>, 12. Juli</td>
                    <td>
                        Ankunft Lena und Thilo im Zillertal
                    </td>
                </tr>
                <tr>
                    <td>Do<span className="xs-hide">nnerstag</span>, 13. Juli</td>
                    <td>
                        <p>
                            Teilweise Anreise Gäste
                        </p>
                        <p>
                            Eventuell gemeinsames Abendessen
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        Fr<span className="xs-hide">eitag</span>, 14. Juli
                    </td>
                    <td>
                        <p>
                            Anreise über den Tag
                        </p>
                        <p>
                            12 Uhr <br/>
                            Standesamtliche Trauung <br/>
                            mit anschließendem kleinen Sektempfang <br/>
                            Standesamt Mayrhofen <br/>
                            (natürlich kein Pflichtprogramm ;))
                        </p>
                        <p>
                            ab ca. 15:30 Uhr <br/>
                            Optionale gemeinsame Wanderung zur Wedelhütte <br/>
                            Die Wanderung startet an der PlatzlAlm, dauert ca 1,5-2 Stunden und führt von
                            ca 1800m auf 2350m.<br/>
                            Als Alternative zum Wandern, organisieren wir Shuttles ab der PlatzlAlm (ca. 17:30Uhr)
                        </p>
                        <p>
                            ab ca. 17 Uhr <br/>
                            Meet & Greet auf der Wedelhütte
                        </p>
                        <p>
                            17:30 Uhr <br/>
                            Shuttles ab der PlatzlAlm zur Wedelhütte <br/>
                            (Alternative zum Wandern)
                        </p>
                        <p>
                            18:30 Uhr <br/>
                            Entspannter Hüttenabend mit Abendessen
                        </p>
                        <p>
                            ca 21:30 Uhr <small>(oder wie auch immer)</small><br/>
                            Rückweg <br/>
                            Entweder Wanderung mit Kopflampen (bitte selber mitbringen)
                            oder Shuttle
                        </p>
                        <p>
                            <br/>
                            Dress Code: <br/>
                            Zur Trauung tragen wir beide Tracht. Die behalten wir auch zur Wanderung und abends in der Wedelhütte an. <br/>
                            Dies soll aber keine Verpflichtung für euch sein, ebenfalls Dirndl und Lederhose einzupacken, <br/>
                            tragt das, worin ihr Wandern möchtet (nein, Daniel, nackt ist keine Option).
                            Zur Wanderung solltet ihr festes Schuhwerk tragen.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>Sa<span className="xs-hide">mstag</span>, 15. Juli</td>
                    <td>
                        <p>
                            Dress Code: <br/> Cocktail bis festlich passt hervorragend zu unserem Tag
                        </p>
                        <p>
                            13 Uhr <br/>
                            Empfang <br/>
                            mit kleinem Mittagssnack
                        </p>
                        <p>
                            14:30 Uhr <br/>
                            Freie Trauunung
                        </p>
                        <p>
                            ab 15:15 Uhr <br/>
                            Anstoßen mit Kaffee, Kuchen und diversen kalten Getränken <br/>
                            Die Party kann ebenfalls starten, da der DJ schon anwesend ist
                        </p>
                        <p>
                            18:30 Uhr <br/>
                            4-Gänge-Menü plus Hochzeitstorte
                        </p>
                        <p>
                            ca 21 Uhr <br/>
                            ab jetzt nur noch Party open end
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>So<span className="xs-hide">nntag</span>, 16. Juli</td>
                    <td>
                        <p>
                            Abreise teilweise Gäste
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>Mo<span className="xs-hide">ntag</span>, 17. Juli</td>
                    <td>
                        <p>
                            Abreise Brautpaar <br/>
                            Abreise Gäste
                        </p>
                    </td>
                </tr>
            </table>
        </div>
    )
}

export default TimePlanComponent;