import React from 'react';
import {Card} from "react-bootstrap";
import './person.css';

const PersonComponent = (props) => {
    return (
        <Card style={{ width: '15rem' }}>
            <Card.Img variant="top" src={props.profileimage} className="rounded-circle" />
            <Card.Body>
                <Card.Title>{props.name}</Card.Title>
                <Card.Text>
                    {props.children}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default PersonComponent;