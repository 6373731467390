import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import {ThemeProvider,Container,Row,Col,Accordion,Card} from "react-bootstrap";
import { BsGeoAlt,BsCalendarEvent,BsEnvelope,BsHandThumbsDown,BsHandThumbsUp } from "react-icons/bs";
import HeaderComponent from "./components/HeaderComponent/HeaderComponent";
import CountdownComponent from "./components/CountdownComponent/CountdownComponent";
import AccordionItemComponent from "./components/AccordionItemComponent/AccordionItemComponent";
import PersonComponent from "./components/PersonComponent/PersonComponent";
import PlatzlalmComponent from "./components/PlatzlalmComponent/PlatzlalmComponent";
import TimePlanComponent from "./components/TimePlanComponent/TimePlanComponent";
import lenaundthilo from "./assets/images/wiebke-alex_839.jpg";
import borde from "./assets/images/borde.jpg";
import laura from "./assets/images/laura1.jpg";

const App = () => {
    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            {/*<Container>*/}
            {/*    <HeaderComponent></HeaderComponent>*/}
            {/*</Container>*/}
            <CountdownComponent />
            <Container id="invitation" className="content-container p-4">
                <Row>
                    <Col lg={{span: 8, offset: 2}}>
                        <h1 className="text-center">Liebe Familie, Freunde und Kollegen,</h1>
                        <p>
                            wir laden euch herzlich ein unsere Hochzeit auf der PlatzlAlm auf 1800m in den Zillertaler Alpen
                            in Österreich
                            von Freitag bis Sonntag mit uns zu feiern.
                        </p>
                        <p>
                           <b> Folgende Infos (Faktenchecker-geprüft!) solltet ihr vorher kennen... (bitte alles lesen:))</b>
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container id="ablauf" className="p-4">
                <Row>
                    <Col lg={{span: 8, offset: 2}}>
                        <TimePlanComponent />
                    </Col>
                </Row>
            </Container>
            <Container id="faq" className="p-4">
                <Row>
                    <Col lg={{span: 8, offset: 2}}>
                        <h1 className="text-center">Eure Fragen, <br/> unsere Antworten</h1>
                        <Accordion className="pb-4">
                            <AccordionItemComponent header="Müssen wir bei der Anreise etwas beachten?" itemkey="0">
                                <p>
                                    Rechnet mit einer Fahrtzeit von Hannover bis zur PlatzlAlm am Freitag mit 10 Stunden.
                                </p>
                                <p>
                                    Ihr könnt über die mautfreie und landschaftlich schöne Strecke über den Tegernsee und Achensee anreisen
                                    oder schneller mit Maut über die österreichische Autobahn A93 ins Zillertal fahren.
                                </p>
                                <p>
                                    Die PlatzlAlm liegt an der <a href="https://www.zillertaler-hoehenstrasse.com/de/karte" target="_blank">Zillertaler Höhenstraße</a>.
                                    Hierbei handelt es sich um eine Mautstraße, die von 550m auf 1800m führt.
                                    Eine Kabinenbahn auf den Berg gibt es im Sommer nicht, aber Taxis fahren die Strecke aus dem Tal auf den Berg.
                                </p>
                            </AccordionItemComponent>
                            <AccordionItemComponent header="Ist eine Anreise mit dem Zug möglich?" itemkey="1">
                                <p>
                                    Ja, eine Anreise mit dem Zug ist möglich. Eure Endstation wäre der Bahnhof Kaltenbach-Stumm.
                                    Rechnet bei der Planung mit ein, dass ihr rechtzeitig ankommt und noch genug Zeit habt,
                                    mit dem Taxi über die Zillertaler Höhenstraße (geschlossen ab 18Uhr) zur PlatzlAlm gebracht zu werden.
                                    Diese Fahrt dauert ca eine halbe Stunde.
                                </p>
                            </AccordionItemComponent>
                            <AccordionItemComponent header="Wann erwartet Ihr uns im Zillertal?" itemkey="2">
                                <p>
                                    Wie beschrieben, könnt ihr eure Anreise individuell und bereits vor Freitag antreten.
                                    Wir würden euch aber bitten, dass Ihr spätestens 17:20Uhr an der PlatzlAlm seid, um noch mit den
                                    Shuttles zur <a href="https://www.wedelhuette.at" target="_blank">Wedelhütte</a> zu kommen.
                                    Dort starten wir gemeinsam in unser Hochzeitswochenende.
                                </p>
                            </AccordionItemComponent>
                            <AccordionItemComponent header="Wann sollten wir spätestens am Freitag da sein?" itemkey="3">
                                <p>
                                   Wenn ihr am Freitag mit auf die Wedelhütte wandern möchtet, solltet ihr um 15:30Uhr startklar
                                    an der PlatzlAlm stehen.
                                </p>
                                <p>
                                    Falls ihr die Shuttles zu Wedelhütte erreichen sollt, solltet ihr um 17:20Uhr an der PlatzlAlm sein.
                                </p>
                                <p>
                                    Bedenkt in beiden Fällen, dass ihr am besten vorher noch eure Unterkunft bezieht und euch vielleicht noch etwas frisch machen
                                    möchtet. Rechnet dafür ebenfalls noch Zeit ein.
                                </p>
                            </AccordionItemComponent>
                            <AccordionItemComponent header="Was ist, wenn wir die Shuttles zur Wedelhütte verpassen?" itemkey="4">
                                <p>
                                   Selber zur Wedelhütte fahren, könnt ihr leider nicht, da der Weg hinauf privat ist und durch eine Schranke gesperrt ist.
                                </p>
                                <p>
                                    Fragt dann am besten in der PlatzlAlm nach, ob sie euch noch hochbringen würden oder ruft ein Taxi aus dem Tal.
                                </p>
                                <p>
                                    Solltet ihr uns an dem Tag erreichen wollen, ruft bitte Laura oder Borde an, da wir unsere Handys nicht dabei haben werden.
                                </p>
                            </AccordionItemComponent>
                            <AccordionItemComponent header="Wo gibt es wie frühstück?" itemkey="5">
                                <p>
                                   Generell gibt es Frühstück auf der PlatzlAlm.
                                </p>
                                <p>
                                    Für diejenigen, die auf der PlatzlAlm schlafen, ist das Frühstück schon im Preis mit drin.
                                </p>
                                <p>
                                    Diejenigen, die in den Hütten/Ferienwohnungen untergebracht sind, können selber entscheiden, ob
                                    sie in ihrer Unterkunft frühstücken oder auf der PlatzlAlm.
                                </p>
                                <p>
                                    Die Hütten sind mit Küchen ausgestattet, ihr müsstet nur das was ihr Frühstücken möchtet selber mitbringen. <br/>
                                    Bedenkt dabei bitte, <b>dass es oben auf dem Berg keine Einkaufsmöglichkeiten gibt</b> und One-Way ins Tal dauert eine halbe Stunde und
                                    kostet pro Strecke eine Mautgebühr.
                                </p>
                                <p>
                                    Das Frühstück auf der PlatzlAlm kostet pro Person 18€ und sollte mindestens einen Tag vorher angemeldet werden,
                                    entweder bei uns <b>oder</b> direkt in der PlatzlAlm.
                                </p>
                            </AccordionItemComponent>
                            {/*<AccordionItemComponent header="Wie seid ihr denn auf den Trichter gekommen?" itemkey="-3">*/}
                            {/*    <p>*/}
                            {/*        Für uns ist dieser Ort etwas besonderes, hier verbringen wir unsere Winterurlaube,*/}
                            {/*        hier haben wir uns verlobt, hier fühlen wir uns wohl*/}
                            {/*        und können uns deswegen keinen besseren Ort für unsere Hochzeit vorstellen.*/}
                            {/*    </p>*/}
                            {/*</AccordionItemComponent>*/}
                            {/*<AccordionItemComponent header="Das ist ja das ganze Wochenende..." itemkey="-2">*/}
                            {/*    <p>*/}
                            {/*        Ja! Ist das nicht geil? Ein ganzes Wochenende in den Bergen mit vielen tollen Menschen*/}
                            {/*        und einer fetten Party!*/}
                            {/*    </p>*/}
                            {/*    <p>*/}
                            {/*        Und wenn ihr wollt, könnt ihr dies direkt mit eurem Sommerurlaub verbinden.*/}
                            {/*        Eine Reise an den Gardasee, ans Mittelmeer oder auch ein Städtetrip nach Wien lassen sich super*/}
                            {/*        mit der An- oder Abreise zur PlatzlAlm verbinden.*/}
                            {/*    </p>*/}
                            {/*</AccordionItemComponent>*/}
                            {/*<AccordionItemComponent header="Können wir wirklich erst am Freitag anreisen?" itemkey="-1">*/}
                            {/*    <p>*/}
                            {/*        Selbstverständlich nicht. Wir sind mit allen Chalets flexibel in Kontakt. Gerne könnt ihr schon*/}
                            {/*        Donnerstag oder noch eher anreisen und auch erst am Montag abreisen, solltet ihr am Sonntag in Ruhe ausschlafen wollen.*/}
                            {/*    </p>*/}
                            {/*    <p>*/}
                            {/*        Auch wir werden bis Montag bleiben und auch vor Freitag anreisen. Falls das für euch eine Option ist,*/}
                            {/*        teilt es uns bei eurer Rückmeldung mit, wir finden für alles eine Lösung ;)*/}
                            {/*    </p>*/}
                            {/*</AccordionItemComponent>*/}
                            {/*<AccordionItemComponent header="So eine lange Fahrt mit vielen Autos? Zeit? Umwelt?" itemkey="1">*/}
                            {/*    <p>*/}
                            {/*        Daran haben wir auch gedacht. Aber, wenn Ihr die Fahrt mit eurem Sommerurlaub verbindet,*/}
                            {/*        dann ist es schon nicht mehr ganz so viel.*/}
                            {/*    </p>*/}
                            {/*    <p>*/}
                            {/*        Ansonsten unterstützen wir euch organisatorisch auch gerne bei der gemeinsamen Anreise in Fahrgemeinschaften/mit 9ern.*/}
                            {/*    </p>*/}
                            {/*</AccordionItemComponent>*/}
                            {/*<AccordionItemComponent header="Zwei Übernachtungen auf einer Alm + Anreise? Das wird doch bestimmt teuer?" itemkey="4">*/}
                            {/*    <p>*/}
                            {/*        Im Blick auf die aktuelle Spritpreisentwicklung*/}
                            {/*        werden wir uns bemühen, dass die Kosten für euch im Rahmen bleiben. Hierfür haben wir*/}
                            {/*        viele Ideen, welche von der Anzahl der Gäste abhängen.*/}
                            {/*    </p>*/}
                            {/*    <p>*/}
                            {/*        Für die zwei Übernachtungen kommen auf euch <b>maximal 90€ in den Chalets und 100€ in der PlatzlAlm*/}
                            {/*        pro Person pro Nacht</b> zu.*/}
                            {/*        Das inkludiert auch das gemeinsame Frühstück auf der PlatzlAlm am Samstag und Sonntag.*/}
                            {/*    </p>*/}
                            {/*    <p>*/}
                            {/*        Und auf Geschenke verzichten wir gerne. Das größte Geschenk, dass ihr uns machen könnt,*/}
                            {/*        ist mitzukommen!*/}
                            {/*    </p>*/}
                            {/*</AccordionItemComponent>*/}
                            <AccordionItemComponent header="Wo würden wir übernachten?" itemkey="6">
                                <p>
                                    Direkt auf dem Berg.
                                </p>
                                <p>
                                    Die PlatzlAlm bietet 8 Zimmer für bis zu 30 Personen, die wir auch verpflichtend buchen müssen.
                                    Innerhalb von 5 Gehminuten liegen weitere kleine komfortable Hütten für 4 bis 8 Personen.
                                </p>
                                <p>
                                    Wir haben für alle Unterkünfte auf dem Berg in unmittelbarer Nähe organisiert.
                                </p>
                            </AccordionItemComponent>
                            <AccordionItemComponent header="Was wünscht ihr euch von uns?" itemkey="7">
                                <p>
                                    EUCH.
                                </p>
                                <p>
                                    Wir wissen, dass diese Hochzeit zeitlich und auch finanziell unerwartet ist, daher ist
                                    es das größte Geschenk für uns, wenn ihr mit uns ein wunderschönes Hochzeitswochende in den Alpen verbringt.
                                </p>
                            </AccordionItemComponent>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
            <Container id="location" className="p-4">
                <Row>
                    <Col lg={{span: 8, offset: 2}}>
                        <h1 className="text-center">Die PlatzlAlm</h1>
                        <p className="text-center">
                            Und hier ein paar Eindrücke von der Location
                        </p>
                    </Col>
                    <PlatzlalmComponent />
                </Row>
            </Container>
            <Container id="trauzeugen" className="p-4">
                <Row>
                    <Col lg={{span: 8, offset: 2}}>
                        <h1 className="text-center">Unsere Trauzeugen</h1>
                        <p className="text-center">
                            Falls ihr Lust habt, etwas an dem Wochenende beizutragen oder vorzubereiten oder ihr uns am Freitag kontaktieren möchtet,
                            bitten wir euch, euch mit unseren Trauzeugen Laura und Borde in Verbindung zu setzen.
                        </p>
                    </Col>
                </Row>
                <Row className="pt-4">
                    <Col lg={{span: 4, offset: 2}}>
                        <PersonComponent profileimage={laura} name="Laura Basedow">
                            <a href="tel:+49 172 31 22 666" className="link">+49 172 31 22 666</a>
                        </PersonComponent>
                    </Col>
                    <Col lg={{span: 4}}>
                        <PersonComponent profileimage={borde} name="Johannes (Borde) Möller">
                            <a href="tel:+49 170 32 66 291" className="link">+49 170 32 66 291</a>
                        </PersonComponent>
                    </Col>
                </Row>
            </Container>
            <Container id="wir" className="p-4">
                <Row>
                    <Col lg={{span: 8, offset: 2}}>
                        <h1 className="text-center">Vielen Dank</h1>
                        <p>
                            ...für eure vielen positiven Rückmeldungen!
                        </p>
                        <p>
                            Aktuell sammeln wir eure Rückmeldungen bzgl wandern oder shuttlen für Freitag :)
                            Falls ihr euch dazu noch nicht geäußert habt, würden wir euch darum bitten.
                        </p>
                        <p>
                            Anschließend zu unserem Hochzeitswochenende in Österreich, schmeißen wir noch eine <b>After-Wedding-Party am 12. August</b> (Dress Code: Smart Casual).
                            Die Party startet gegen 17:30Uhr und findet in der Scheune in Solschen bei Lenas Eltern (Eschenweg 10, 31241 Ilsede) statt.
                        </p>
                        <p>
                            Und schaut ab und zu hier nochmal vorbei. Im Laufe der Zeit werden weitere Infos hinzukommen.
                        </p>
                        <p>
                            Letztes Update: 19.06.2023
                        </p>
                    </Col>
                </Row>
                <Row className="pt-4">
                    <Col lg={{span: 6, offset: 3}}>
                        <PersonComponent profileimage={lenaundthilo} name="">

                            <h2 className="text-center">
                                Lena & Thilo
                            </h2>
                            <p>
                                Lena: <a href="tel:+49 176 61 32 54 67" className="link">+49 176 61 32 54 67</a> <br/>
                                Thilo: <a href="tel:+49 179 909 26 07" className="link">+49 179 909 26 07</a>
                            </p>
                        </PersonComponent>
                    </Col>
                </Row>
            </Container>
            <Container id="summary" className="p-4">
                <Row>
                    <Col lg={{span: 8, offset: 2}}>
                        <h1 className="text-center">Zusammenfassung</h1>
                        <h2 className="text-center">Hochzeit</h2>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            <BsCalendarEvent size={40} />
                                        </Card.Title>
                                        <Card.Text>
                                            14. - 16. Juli 2023
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            <BsGeoAlt size={40} />
                                        </Card.Title>
                                        <Card.Text>
                                            PlatzlAlm, Kaltenbach, Tirol, Österreich
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            <BsHandThumbsDown size={40} /><BsHandThumbsUp size={40} />
                                        </Card.Title>
                                        <Card.Text>
                                            Bis zum 14. Juli 2022
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <h2 className="text-center mt-5">After-Wedding-Party</h2>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            <BsCalendarEvent size={40} />
                                        </Card.Title>
                                        <Card.Text>
                                            12. August 2023
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            <BsGeoAlt size={40} />
                                        </Card.Title>
                                        <Card.Text>
                                            Scheune, Eschenweg 10, Solschen, Deutschland
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            <BsHandThumbsDown size={40} /><BsHandThumbsUp size={40} />
                                        </Card.Title>
                                        <Card.Text>
                                            Bis zum 14. Juli 2023
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>
        </ThemeProvider>
    )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

