import React from 'react';
import './countdown.css';
import secondSlide from "../../assets/images/nfl.jpg";

class CountdownComponent extends React.Component {
    state = {
        date: new Date('July 14, 2023 18:00:00'),
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    }
    componentDidMount() {
        const that = this;
        const x = setInterval(function() {
            const eventTime = that.state.date.getTime();
            const now = new Date().getTime();
            const distance = eventTime - now;

            that.setState({ days: Math.floor(distance / (1000 * 60 * 60 * 24)) });
            that.setState({ hours: that.padTime(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))) });
            that.setState({ minutes: that.padTime(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))) });
            that.setState({ seconds: that.padTime(Math.floor((distance % (1000 * 60)) / 1000)) });

            if(distance < 0) {
                clearInterval(x);
                that.setState({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0
                })
            }
        }, 1000);
    }
    padTime(time) {
        return (time < 10 ? '0' : '') + time;
    }

    render () {
        return (
            <div className="countdown-container">
                <img
                    className="d-block w-100"
                    src={secondSlide}
                    alt="First slide"
                />
                <div className="countdown-wrapper">
                    <div className="date">15. Juli 2023</div> <br/>
                    <div className="countdown">
                        <div className="days">
                            <div className="time">{this.state.days}</div>
                            <div>Tage</div>
                        </div>
                        <div className="hours">
                            <div className="time">{this.state.hours}</div>
                            <div>Std</div>
                        </div>
                        <div className="minutes">
                            <div className="time">{this.state.minutes}</div>
                            <div>Min</div>
                        </div>
                        <div className="seconds">
                            <div className="time">{this.state.seconds}</div>
                            <div>Sek</div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

}

export default CountdownComponent;