import React from 'react';
import {Accordion} from "react-bootstrap";
import './accordion.css';

const AccordionItemComponent = (props) => {
    return (
        <Accordion.Item eventKey={props.itemkey}>
            <Accordion.Header>{props.header}</Accordion.Header>
            <Accordion.Body>
                {props.children}
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default AccordionItemComponent;